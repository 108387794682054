import $ from 'jquery';
import React from 'react';

class Shots extends React.Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    const apiKey = '6ed47da4bdaaf76d182cc8eabf5fe30411f6f2d1a13c99ff33a36b5209761c06';

    this.mounted = true;

    $.get(this.props.source + apiKey, function(result) {
      var userInfo = result;
      this.setState({
        data: userInfo
      });
    }.bind(this));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let dataList = this.state.data.map((index) => {
      return (
        <li className="Shots-item Grid-cell u-size11of12 u-md-size4of12 u-lg-size3of12 u-pt" key={index.id}>
          <a href={index.html_url}>
            <img src={index.images.normal} />
          </a>
        </li>
      )
    });

    return (
      <section className="Shots InnerWrapper">
        <ul className="Grid Grid--alignCenter Grid--withGutter u-mt+ [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
          {dataList}
        </ul>
      </section>
    );
  }
};

export default Shots;
