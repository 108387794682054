import React from 'react';
import { Link } from 'react-router';

class Header extends React.Component {
  render() {
    return (
      <header className="InnerWrapper Grid Grid--alignCenter u-md-ph [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
        <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
          <div className="Header Header-inner u-sm-pt">
            {/*<Link to="/"><h1 className="Header-logo u-mb0"><img src="src/images/logo.png" alt="" /></h1></Link>*/}
            <Link className="Header-logo u-mb0" to="/">
              {/*<img src="src/images/logo.png" alt="" />*/}

              {/*<picture>
                <source srcSet="src/images/logo@2x.png 96w,
                                src/images/logo.png 48w"
                        sizes="(min-width: 640px) 48px, 96px" />
                <source srcSet="src/images/logo.png 1x, src/images/logo@2x.png 2x" />
                <img src="src/images/logo.png" alt="" />
              </picture>*/}

              <img srcSet="src/images/logo.png 1x, src/images/logo@2x.png 2x"
                   src="src/images/logo.png"
                   alt="" />

            </Link>

            <nav className="HeaderNavigation">
              <ul className="u-mb0">
                <li>
                  <a href="./themes">Themes</a>
                </li>
                <li>
                  <a href="./bookworm">Bookworm</a>
                </li>
                <li>
                  <a href="https://www.dropbox.com/s/z7hws7ouhwnvmz0/cv.pdf?dl=0">Curriculum Vitae</a>
                </li>
                <li>
                  <a href="http://maciejjasinski.tumblr.com">Photography</a>
                </li>
                {/* <li>
                  <Link to="/about">Contact</Link>
                </li>
                <li>
                  <Link to="/about">Freebies</Link>
                </li> */}
              </ul>
            </nav>

          </div>
        </div>
      </header>
    );
  }
};

export default Header;
