import React from 'react';
import { Link } from 'react-router';

class Cv extends React.Component {
  render() {
    return (
      <h1>CV</h1>
    );
  }
};

export default Cv;
