import React from 'react';

class Contact extends React.Component {
  render() {
    return (
      <section className="Section InnerWrapper Grid Grid--alignCenter u-md-sectionMargin u-lg-sectionMargin u-sm-mt++ [ u-md-pv+ u-lg-pv+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
        <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
          <h1 className="u-sm-mb0">Contact</h1>
        </div>
        <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12 u-mt">
          <h2>Got interested?<br></br>Want to discuss something?</h2>
        </div>
        <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
          <article>
            <p>Don’t hesitate to drop me an email <br className="u-textBreakeOnMdLg"></br>
            on <a href="mailto:contact@maciejjasinski.com" className="u-underline">contact@maciejjasinski.com</a>, <br className="u-textBreakeOnMdLg"></br>
            or contact me via my social profiles:</p>
            <nav className="SocialNavigation">
              <span>
                <a href="http://dribbble.com/siara">
                  <img srcSet="src/images/dribbble.png 1x, src/images/dribbble@2x.png 2x"
                       src="src/images/dribbble.png"
                       alt="" />
                </a>
              </span>
              <span>
                <a href="http://github.com/siara">
                  <img srcSet="src/images/github.png 1x, src/images/github@2x.png 2x"
                       src="src/images/github.png"
                       alt="" />
                </a>
              </span>
              <span>
                <a href="http://twitter.com/maciej_jasinski">
                  <img srcSet="src/images/twitter.png 1x, src/images/twitter@2x.png 2x"
                       src="src/images/twitter.png"
                       alt="" />
                </a>
              </span>
              <span>
                <a href="http://instagram.com/jasinskim">
                  <img srcSet="src/images/instagram.png 1x, src/images/instagram@2x.png 2x"
                       src="src/images/instagram.png"
                       alt="" />
                </a>
              </span>
            </nav>
          </article>
        </div>
      </section>
    );
  }
};

export default Contact;
