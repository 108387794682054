import React from 'react';
import { Link } from 'react-router';
import LazyLoad from 'react-lazy-load';

class Clockwork extends React.Component {
  render() {
    return (
      <main className="Projects">

        <header className="ProjectsHeader ProjectsHeader--clockwork">
          <Link to="/" className="ProjectsExit"><span></span></Link>
          <div className="ProjectsHeader-content InnerWrapper u-md-pb+ u-lg-pb+ u-sm-ph-">
            <h1>Clockwork (Pirx3d)</h1>
          </div>
        </header>

        <section className="ProjectsContent InnerWrapper">

          <header className="ProjectsMeta ProjectsContent-separator Grid Grid--alignCenter u-pv+ u-md-pv++ u-lg-pv++">
            <div className="Grid-cell u-size11of12 u-md-size2of12 u-lg-size2of12">
              <h4 className="ProjectsMeta-label u-mb0">Year</h4>
              <h3 className="u-mb0">2013</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-md-after2of12 u-lg-after2of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Scope:</h4>
              <h3 className="u-mb0">Visual Identity, Logotype, Website</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size5of12 u-lg-size5of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Tools:</h4>
              <h3 className="u-mb0">Photoshop, Illustrator, HTML, CSS, JS, jQuery, Zurb Foundation</h3>
            </div>
          </header>

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size5of12 u-lg-size5of12 u-md-after2of12 u-lg-after2of12">
                <img src="src/images/clockwork-logo.png" />
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt+">
                <h2>About</h2>
                <p>Clockwork* is a hardware oriented company based in Cracow. They have designed and built Pirx - the most affordable and reliable 3D printer on the market. To bring you the best product there is they assembled a diversely skilled team. They handle everything themselves - from mechanical design to software and electronics.</p>
                <p>They love technology. They love progress. They cherish everyday human ingenuity. That’s why they want to enable everyone to participate in our vision - they would like to see a world where anyone can be both an inventor and a producer. They won’t get there without innovative, smart CNC tools - tools they want to create.</p>
                <span>* To clarify, the initial name of the company was, and the whole project was created with this name in mind. Later on, due to some circumstances, they've renamed it to Pirx3d and only the name in the logo was changed. To stay consistent, I'll refer to them as Clockwork.</span>
              </div>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pv++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Problem</h2>
              <p>As many young companies they’ve started with a quickly sketched logotype and a very simple, bootstrapped website.</p>
              <p>Nowadays the visual language is becoming much more important, as it's creating a kind of bond between the company and the consumer.</p>
              <p>Guys from Clockwork were lacking this distinct sense of identity, something that would make them recognizable on the tightly-packed market of 3d printers.</p>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt+">
              <h2>To be done</h2>
              <p>The general goal was to make them stand out in the market. How to be visible among other players like for example Makerbot.</p>
              <p>I was also supposed to help them switch to the more mature phase of their development. It's a cliche, but making the branding visually pleasing and fresh was what I focused on.</p>
              <p>To list the most important task we agreed on, I'd have to mention:</p>
              <ul>
                <li>1) tracing the general guidelines of the identity</li>
                <li>2) creating a logotype / picking up colors and typography</li>
                <li>3) designing and developing a proper product website</li>
              </ul>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>General feeling</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>The message we wanted to send to the consumers, was that this product is reliable but at the same time affordable for everyone.</p>
              <p>We didn't want people to see a spaceship-like piece of modern tech, but rather something that they'd place as a piece of decoration at their homes.</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/clockwork-feeling.jpg" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pv++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Logotype</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>The idea behind the logotype was pretty straightforward. As the company was named Clockwork the first and obvious choice was the gearwheel symbol. And we sticked with this one.</p>
            </div>

            <div className="ProjectImage ProjectImage--center u-sm-ph- u-pv++">
              <LazyLoad>
                <img src="src/images/clockwork-logo-study.png" />
              </LazyLoad>
            </div>

          </div>

        </section>

        <section className="ProjectsContent">

          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Website</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>We could say that the tech startup website has to fulfill some rules:</p>
              <ul>
                <li>1) showcase the product in an easy and understandable way</li>
                <li>2) has a convenient flow of pre/ordering, signing up for beta access</li>
                <li>3) show the most current version of the product, the one that's closest to the version that will be shipped</li>
                <li>4) has the technical specification / frequently asked questions / easy support access</li>
              </ul>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-pv++">
            <LazyLoad>
              <img src="src/images/clockwork-website.jpg" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
                <h2>Colors</h2>
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
                <p>I needed one strong color that would stay in the consumer’s memory as the one associated with Clockwork. I’ve picked the '#FF7200' orange as it is fresh, presents nicely along warm shades of grey and works great with the wooden material of their first 3d printer.</p>
                <p>As the complementary set I’ve created a group of warm greyshades for typography, backgrounds, icons, accents, etc.</p>
              </div>
            </div>
            <div className="ProjectImage ProjectImage--center u-pt++">
              <LazyLoad>
                <img src="src/images/clockwork-colors.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
                <h2>Typography</h2>
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
                <p>There were two important aspects of the font I needed to consider:</p>
                <ul>
                  <li>1) tech feeling of the company</li>
                  <li>2) simplicity</li>
                </ul>
                <p>I was looking for a sans-serif font that would be clearly readable on web as well as on print (printers itself, technical elements, etc.)</p>
              </div>
            </div>
            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/clockwork-typography.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Icons</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>They were mainly used to help visually describe different technical parts of the device.</p>
              <p>As 3d printer is a quite complicated mechanical device, there were some challenging items to present.</p>
              <p>Things like: printer bed, fillament, dimensions, mechanical elements, temperature or printing technology details.</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/clockwork-icons.png" />
              </LazyLoad>
            </div>
          </div>

        {/*</section>

        <section className="ProjectsContent">

          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>UI Elements</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
            <p>I was supposed to help them enter a more mature phase of a startup. Making the branding visually pleasing and fresh was the priority. I was focused on:</p>
            <ul>
              <li>1) grounding the general rules of the identity</li>
              <li>2) creating a logotype</li>
              <li>3) designing and developing a product website.</li>
            </ul>
            </div>
          </div>

          <div className="Grid u-pv++">
            <div className="Grid-cell u-size12of12 u-md-size6of12 u-lg-size6of12">
              <LazyLoad>
                <img src="src/images/clockwork-uielements-1.png" />
              </LazyLoad>
            </div>
            <div className="Grid-cell u-size12of12 u-md-size6of12 u-lg-size6of12">
              <LazyLoad>
                <img src="src/images/clockwork-uielements-2.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">*/}

          <div className="Grid Grid--alignCenter u-pt++ u-pb+ u-md-pb++ u-lg-pb++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Credits</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>This project wouldn't come to life if not for the help from many great people. I'd like to mention and really thank them for all the cooperation and help :)</p>
              <ul className="ProjectsCredits ProjectsCredits--clockwork">
                <li>- <a href="https://www.youtube.com/channel/UCEbenDO5dUqFlZb9uhZ3c8g">Jef</a>, for helping with the photos (without them the website would have been dead) and for shooting a great product video for guys from Pirx</li>
                <li>- <a href="http://www.pigsodus.com">Piotr</a>, for giving me the opportunity to work on this awesome project, and trusting my design-judgement on this one</li>
                <li>- the whole Pirx team</li>
                <li>- and all the other people involved in the process</li>
              </ul>
            </div>
          </div>

        </section>

      </main>
    )
  }
}

export default Clockwork;
