import React from 'react';

class Dribbble extends React.Component {
  render() {
    return (
      <section className="Section InnerWrapper Grid Grid--alignCenter u-sm-mt++ u-md-sectionMargin u-lg-sectionMargin [ u-md-pv+ u-lg-pv+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
        <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
          <h1 className="u-sm-mb0">Dribbble</h1>
        </div>
        <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12 u-mt">
          <h2>Check out my recent <br className="u-textBreakeOnMdLg"></br>shots!</h2>
        </div>
        <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
          <article>
            <p>I won't lie if I told you that thanks to this community, I am where I am today.</p>
            <p>Active participation in its life elevated my skills and created opportunities, I could've only dreamt of before.</p>
          </article>
        </div>
      </section>
    );
  }
};

export default Dribbble;
