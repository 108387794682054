import React from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import autotrack from 'autotrack';

import routes from './config/routes';

class App extends React.Component {
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

ReactDOM.render(
  routes,
  document.getElementById('app')
);
