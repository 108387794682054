import $ from 'jquery';
import React from 'react';

class ThemesFaq extends React.Component {
  render() {
    return (

      <section className="FAQ Shots InnerWrapper">
        <div className="Section Grid Grid--alignCenter u-sm-mt++ u-md-sectionMargin u-lg-sectionMargin [ u-md-pv+ u-lg-pv+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
          <div>
            <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
              <h1 className="u-sm-mb0">FAQ</h1>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12 u-mt">
              <h2>All the most common<br className="u-textBreakeOnMdLg"></br>questions and answers.</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
              <article>
                <p>Create a unique experience for you visitors with my themes. You won't be disappointed by their versatility and adjustability along with a standing out design and hand-crafted features.</p>
              </article>
            </div>
          </div>
        </div>


        <div className="Grid Grid--alignCenter u-sm-mt+ [ u-md-pv+ u-lg-pv+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
          <div className="FAQ-toc Grid Grid--withGutter Grid--alignCenter u-md-after1of12 u-lg-after1of12">
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-mt">
              <ul>
                <li><h3>1. Installation</h3></li>
                <li><a href="#">How to install theme on my tumblr?</a></li>
                <li><a href="#">There is a newer version. How do I update?</a></li>
              </ul>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-mt">
              <ul>
                <li><h3>2. Setup / Customization</h3></li>
                <li><a href="#">How do I add my title / description?</a></li>
                <li><a href="#">How can I add my custom logo?</a></li>
                <li><a href="#">How do I add a custom Google Font?</a></li>
                <li><a href="#">How do I choose and add my custom social links?</a></li>
                <li><a href="#">How to use theme's mobile version instead of tumblr's default one?</a></li>
              </ul>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-mt">
              <ul>
                <li><h3>3. Purchase</h3></li>
                <li><a href="#">Where can I buy your themes?</a></li>
                <li><a href="#">How can I get a refund?</a></li>
              </ul>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-mt">
              <ul>
                <li><h3>4. Other</h3></li>
                <li><a href="#">My theme crashed! Everything looks terrible! What do I do?</a></li>
                <li><a href="#">Would you customize the theme for me?</a></li>
                <li><a href="#">Do you create custom made themes?</a></li>
              </ul>
            </div>
          </div>
        </div>


        <div><hr className="FAQ-sectionDivider"></hr></div>


        <ul className="FAQ-content Grid Grid--alignCenter u-mt+ [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
          <ul className="Section Grid Grid--alignCenter u-mt+ [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <h2>1. Installation</h2>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">How to install theme on my tumblr?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <ol>
                    <li>Open the index.html file provided in the package, using some text editor (i.e. <a href="https://atom.io">Atom</a>, <a href="https://www.sublimetext.com">Sublime Text</a>)</li>
                    <li>Copy the contents of the file</li>
                    <li>Open the tumblr editor in the <i>'Edit appearance'</i> menu of your account</li>
                    <li>Paste everything you've copied</li>
                    <li>Click <i>'Save'</i> and <i>'Update Preview'</i></li>
                    <li>You're good to go! You should see the new theme in action (Sometimes you'll see the changes in the editor immediately, but not on you page. No worries, you'll just need to wait a few minutes for a theme to replace the old one.)</li>
                 </ol>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">There is a newer version. How do I update?</h3>
                  <span className="FAQ-warning u-mb u-mt">Caution! Before updating make sure you backed up your theme by copying everything from your page editor. Sometimes things can crash, or you won't like some of the new features, and you'd like to revert to the previous version.</span>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>If you bought the theme through the tumblr shop, just go there, find the theme, and click use.</p>
                  <p>If you bought a package with an <i>.html</i> file, just copy the contents of the file and paste them in the theme editor (as if you were installing it).</p>
                </div>
              </article>
            </li>
          </ul>


          <div><hr className="FAQ-sectionDivider"></hr></div>


          <ul className="Section Grid Grid--alignCenter u-mt+ [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <h2>2. Setup / Customization</h2>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">How do I add my title / description?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Just go to the tumblr editor and you'll see an appropriate text-fields: <i>'Title'</i>, <i>'Description'</i>. Just fill them in, and you're good to go.</p>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">How can I add my custom logo?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Just go to the tumblr editor and you'll see a <i>'Logo'</i> button. Just click it, pick your logo file and it should replace the default one.</p>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">How do I add a custom Google Font?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>First of all go to <a href="https://fonts.google.com">fonts.google.com</a>, choose the font you like. Then in the tumblr editor options find <i>'Google Web Font Name'</i> and type in the name of the font you want to use. It's that simple!</p>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">How do I choose and add my custom social links?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Just go to the tumblr editor and you'll see text fields responsible for each social medium you want to use. Just type in the url of your profile and it's done. To make sure everything works ok, go to each of your profiles and copy the url from the browser. It should look similar to: <i>'https://twitter.com/YOUR_USERNAME'</i>.</p>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">How to use theme's mobile version instead of tumblr's default one?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Go to the tumblr editor and click on the <i>'Advanced options'</i> link. Then just find a switch labeled <i>'Use default mobile theme'</i>, and make sure it's unchecked / off. Afters saving your changes the custom mobile layout should be working.</p>
                </div>
              </article>
            </li>
          </ul>


          <div><hr className="FAQ-sectionDivider"></hr></div>


          <ul className="Section Grid Grid--alignCenter u-mt+ [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <h2>3. Purchase</h2>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">Where can I buy your themes?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>I don't sell them directly, but you can buy all of my themes on: <a href="#">Creative Market</a>.</p>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">How can I get a refund?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Because I sell my themes only via <a href="#">Creative Market</a> (I don't sell the themes myself), you should get in touch with their support. They are always very helpful and make no problems with refunds.</p>
                </div>
              </article>
            </li>
          </ul>


          <div><hr className="FAQ-sectionDivider"></hr></div>


          <ul className="Section Grid Grid--alignCenter u-mt+ [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <h2>4. Other</h2>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size5of12 u-lg-after1of12 u-mt">
                  <h3 className="u-mb0">My theme crashed! Everything looks terrible! What do I do?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Calm down :) We'll deal with that. Sometimes this may happen, especially when installing a new theme. Just grab the contents of the index.html file, provided in the original package, and copy it in your theme menu. The process is the same as for installation, so look up to that question. Everything should be ok now.</p>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12 u-mt">
                  <h3 className="u-mb0">Would you customize the theme for me?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Unfortunately I don't do theme customizations at the moment.</p>
                </div>
              </article>
            </li>
            <li className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12 u-pt">
              <article>
                <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12 u-mt">
                  <h3 className="u-mb0">Do you create custom made themes?</h3>
                </div>
                <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
                  <p>Unfortunately I don't create custom themes at the moment.</p>
                </div>
              </article>
            </li>
          </ul>
        </ul>
      </section>
    );
  }
};

export default ThemesFaq;
