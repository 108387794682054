import $ from 'jquery';
import React from 'react';

class ThemesFeatures extends React.Component {
  render() {
    return (

      <section className="Shots InnerWrapper">
        <div className="Section Grid Grid--alignCenter u-sm-mt++ u-md-sectionMargin u-lg-sectionMargin [ u-md-pv+ u-lg-pv+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
          <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
            <h1 className="u-sm-mb0">Features</h1>
          </div>
          <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12 u-mt">
            <h2>All the themes are<br className="u-textBreakeOnMdLg"></br>much more than just<br className="u-textBreakeOnMdLg"></br>static websites.</h2>
          </div>
          <div className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
            <article>
              <p>Create a unique experience for you visitors with my themes. You won't be disappointed by their versatility and adjustability along with a standing out design and hand-crafted features.</p>
            </article>
          </div>
        </div>




        <ul className="Grid Grid--alignCenter Grid--withGutter u-mt+ [ u-md-ph u-lg-ph- u-xlg-ph0 ] u-lg-after1of12">
          <li className="Shots-item Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-pt">
            <article>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <path fill="none" stroke="#000" strokeMiterlimit="10" d="M9.5 21.5c0 1-1 2-2 2s-2-1-2-2V8l2-4.5 2 4.5v13.5zm-4-1h4m-4-2h4m3-18h6v23h-6zm0 3h2m-2 4h2m-2 4h2m-2 4h2m-2 4h2M5.5 8h4m-2 0v10.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <h2 className="u-kilo u-mt">Professionally designed</h2>
              <p className="u-delta">
              The functional UX, accompanied by slick & modern design
              will make your content pop and
              increase the visitor satisfaction.</p>
            </article>
          </li>
          <li className="Shots-item Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-pt">
            <article>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <g fill="none" stroke="#000" strokeMiterlimit="10" strokeLinejoin="round">
                  <path d="M21.5 4.5v15m-17-17h15m-17 17v-15m17 17h-15"/>
                  <path d="M.5.5h4v4h-4zm19 0h4v4h-4zm-19 19h4v4h-4zm19 0h4v4h-4z" strokeLinecap="round"/>
                </g>
              </svg>
              <h2 className="u-kilo u-mt">Highly customizable</h2>
              <p className="u-delta">
              The basic configuration is always the simplest one,
              but with simple switches and text fields,
              you'll be able to fully adjust the looks of
              the theme, to fit your needs.</p>
            </article>
          </li>
          <li className="Shots-item Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-pt">
            <article>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <path fill="none" stroke="#000" strokeMiterlimit="10" d="M6.5.5v23m5-23v23m5-23v23m7-17H.5m23 5H.5m23 5H.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <h2 className="u-kilo u-mt">Fully responsive</h2>
              <p className="u-delta">
              Each and every theme is build with the
              mobile-first approach, so you can be sure
              that it will stay sharp & crips, no matter
              what is the device, the page is viewed on.</p>
            </article>
          </li>




          <li className="Shots-item Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-pt">
            <article>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <g fill="none" stroke="#000" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M19.5 11v12.5H.5v-21h16"/>
                  <path d="M13.6 11.8l-2.8 1.4 1.4-2.8L21.8.8c1-1 2.3.5 1.4 1.4l-9.6 9.6zm6.8-9.6l1.4 1.4"/>
                </g>
              </svg>
              <h2 className="u-kilo u-mt">Thoroughly documented</h2>
              <p className="u-delta">
              Each theme is well described,
              and my FAQ is full of answers to the
              most common questions and tutorials
              on how to deal with specific issues.</p>
            </article>
          </li>
          <li className="Shots-item Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-pt">
            <article>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <path fill="none" stroke="#000" stroke-miterlimit="10" d="M.5 10.35l2.8 4.15 3.2-3.83m16.57 3.98L20.3 10.5l-3.22 3.84m3.17-3.8c.83 4.4-2.27 8.73-6.5 9.73-2.96.7-5.93-.24-7.94-2.22m-2.5-3.57C1.98 9.6 5.26 4.8 9.83 3.73c3.16-.75 6.32.37 8.32 2.64" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <h2 className="u-kilo u-mt">Always up-to-date</h2>
              <p className="u-delta">
              Creating a theme is great, but the
              real value is in maintenance.
              I always try to update the themes
              when necessary, adding features and fixing bugs.</p>
            </article>
          </li>
          <li className="Shots-item Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-pt">
            <article>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <g fill="none" stroke="#000" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M13.5.5v7m-3-3l3 3 3-3"/>
                  <circle cx="19" cy="22" r="1.5"/>
                  <circle cx="8" cy="22" r="1.5"/>
                  <path d="M.5 3.5H4l4 17h11m-11.5-2h13l3-9h-18"/>
                </g>
              </svg>
              <h2 className="u-kilo u-mt">Buy on Creative Market</h2>
              <p className="u-delta">
              All of my themes are available to buy
              on Creative Market that offers a lot
              of digital goods by talented designers.
              </p>
            </article>
          </li>
        </ul>
      </section>
    );
  }
};

export default ThemesFeatures;
