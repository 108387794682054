import React from 'react';
import { Link } from 'react-router';
import LazyLoad from 'react-lazy-load';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class Estimote extends React.Component {
  render() {
    return (
      <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500} transitionLeaveTimeout={500}>
      <main className="Projects">

        <header className="ProjectsHeader ProjectsHeader--estimote">
          <Link to="/" className="ProjectsExit"><span></span></Link>
          <div className="ProjectsHeader-content InnerWrapper u-md-pb+ u-lg-pb+ u-sm-ph-">
            <h1>Estimote</h1>
          </div>
        </header>

        <section className="ProjectsContent InnerWrapper">

          <header className="ProjectsMeta ProjectsContent-separator Grid Grid--alignCenter u-pv+ u-md-pv++ u-lg-pv++">
            <div className="Grid-cell u-size11of12 u-md-size2of12 u-lg-size2of12">
              <h4 className="ProjectsMeta-label u-mb0">Year</h4>
              <h3 className="u-mb0">2012-2016</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-md-after2of12 u-lg-after2of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Scope:</h4>
              <h3 className="u-mb0">iOS/Android, Web, Web App, Icon, Illustration, Marketing - Design</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size5of12 u-lg-size5of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Tools:</h4>
              <h3 className="u-mb0">Sketch, Photoshop, Illustrator, Origami, Html, CSS</h3>
            </div>
          </header>

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
                <LazyLoad>
                  <img src="src/images/estimote-logo.png" />
                </LazyLoad>
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt+">
                <h2>About</h2>
                <p>Estimote, Inc. is a technology start-up building a sensor-based analytics and engagement platform. They develop what we like to call an operating system for physical locations -- one that will change how people run businesses in the physical world and how consumers interact with real world products and venues.</p>
                <span>(source: estimote.com)</span>
              </div>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pv++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>The Story</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>This one will be a bit different than the others. It won't be a typical case study. I wanted to showcase some (merely a small chunk) things I did, while working at Estimote.</p>
              <p>The whole story began in 2012 and was a blast. Not until the moment I joined them, have I seriously thought about designing. This journey was realy eye-opening and the project I wan to share with you below are the result of this trip.</p>
            </div>
          </div>

        </section>

        <section className="ProjectsContent">
          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>iOS App</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>I won't lie if I said that this is the project I've spent the most time on. Dozens of iterations, hundreds of screens, tens of illustrations. That could be the quick, statistical sum up.</p>
              <p>This was in fact the first big project I was doing from my first day at the company. An app that would be used to manage beacons, show usecases or to configure them for the users needs.</p>
              <p>It changed a lot, and the development is the constant process. We'll never know what it's going to do in the future, but it surely will be the core of the beacon management process.</p>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-pv++">
            <LazyLoad>
              <img src="src/images/estimote-ios.png" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Cloud</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>From the very beginning, the design of the cloud became a really hard task to accomplish. There are of course dozens of different dashboards that look crips and work great, but none of them are dealing with beacons, real-life analytics and indoor location  successfully, at the same time.</p>
              <p>I had the pleasure to set the visual guidelines, at the very beginning, but the current feel and look is the result of collaboration of many individuals. Cheers to all of them!</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/estimote-cloud.png" />
              </LazyLoad>
            </div>
          </div>

        </section>

        <section className="ProjectsContent">
          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Indoor Location</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>Every project I mention I label as the 'tough one', but hey, that's the truth. Exploring new areas is never easy, and indoor location aka indoor navigations is the best example.</p>
              <p>No one has yet made a perfect solution for navigating inside of buildings, but this project constantly tries new things and is on the best way to succeed.</p>
              <p>I worked on the mobile app and some web components. I've also created a dedicated estimote.com/indoor page for the project.</p>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-pv++">
            <LazyLoad>
              <img src="src/images/estimote-indoor.png" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Developer's Portal</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>I didn't want the developer's documentation site to be just a dry page with a lot of technical mumbling. That's why I created the interactive guide for the users, with the aim to help them start developing with the beacons.</p>
              <p>Anyone should be able to create their first app in no more than 15 minutes, even without the coding experience. Everything server for Objective-C and Swift.</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/estimote-developers.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Community Portal</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>User satisfaction is the key element of any prosperous company, that's why we decided to really focus on the easy experience for them. Whenever someone is stuck with the tech suite or wants to seek for collaborators, they could just visit estimote community and instantly find the solution.</p>
              <p>The initial version was based on the zendesk's help center portal, with many custom adjustments. Now it's more of a forum-like portal.</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/estimote-community.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Icons</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>The easy part of creating the icons was the fact that the style was already set by my great colleagues. The Linear icons, in dark grey and white color, finished with the contour stroke of 2x width of the interior. Just perfect!</p>
              <p>The other part was that during my stay we've create so many icons, that I wouldn't be suprised if we could make a book with them on each page. Still I really like how they look and feel.</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/estimote-icons.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Illustrations</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>Everything started with the iOS app design. That green background with the vector illustrations. That's how it emerged. Apart from icons and glyph we started using those more picturesque medium, to show more important and complex information.</p>
              <p>As I mentioned earlier, always on the same green background (ok, from time to time also on the white), with the use of the brand color palette where possible. All finished up with other complementary colors.</p>
            </div>
          </div>
          <div className="ProjectsContent-separator Grid u-pv++">
            <div className="Grid-cell u-size12of12 u-md-size6of12 u-lg-size6of12">
              <LazyLoad>
                <img src="src/images/estimote-illustrations-1.png" />
              </LazyLoad>
            </div>
            <div className="Grid-cell u-size12of12 u-md-size6of12 u-lg-size6of12">
              <LazyLoad>
                <img src="src/images/estimote-illustrations-2.png" />
              </LazyLoad>
            </div>
          </div>

        </section>
        <section className="ProjectsContent">

          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Marketing</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>Many could say that it's always the designer's nightmare. I think that it depends on how effective they become. All those email templates, social banners, retargeting images, cover photos, etc.</p>
              <p>The fact is that if they're well designed, they can highly boost the company's traffic and isn't it all about that?</p>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-pv++">
            <LazyLoad>
              <img src="src/images/estimote-marketing.png" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>
        <section className="ProjectsContent InnerWrapper">

          <div className="Grid Grid--alignCenter u-pt++ u-pb+ u-md-pb++ u-lg-pb++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Credits</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>This project wouldn't have come to life if not for the help from many great people. I'd like to mention and really thank them for all the cooperation and help :)</p>
              <ul className="ProjectsCredits ProjectsCredits--estimote">
                <li>- <a href="http://nomad-things.com">Gabi</a>, <a href="http://oyo.com.pl/">Olga</a>, <a href="https://www.behance.net/melisagalvao">Melisa</a>, <a href="https://www.facebook.com/sewimedia">Sewi</a> for helping me grow from a beginner designer, to a more mature version of myself, aware of the design principles and much more confident in what I do.</li>
                <li>- and all the other people involved in my journey at Estimote</li>
              </ul>
            </div>
          </div>

        </section>

      </main>
      </ReactCSSTransitionGroup>
    )
  }
}

export default Estimote;
