import React from 'react';

class Work extends React.Component {
  render() {
    return (
      <section className="Section InnerWrapper Grid Grid--alignCenter u-sm-mt++ u-md-sectionMargin u-lg-sectionMargin [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
        <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
          <h1 className="u-mega u-sm-mb0">Work</h1>
        </div>
        <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-mt">
          <h2 className="u-kilo">Designing is about solving problems.</h2>
        </div>
        <article className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before3of12 u-lg-before3of12 u-md-after1of12 u-lg-after1of12 u-mt">
          <p className="u-delta">
          I've been creating digital products for the last couple of years. I try to always be involved throughout the whole process. From initial sketch to final deployment.
          </p>
          <p className="u-delta">
          To see more just check out my projects:
          </p>
        </article>
      </section>
    );
  }
};

export default Work;
