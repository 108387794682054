import React from 'react';
import { Link } from 'react-router';
import $ from 'jquery';

class ThemesHero extends React.Component {
  scrollToBrowse() {
    $('html, body').animate({
        scrollTop: $('.js-browseHook').offset().top
    }, 600);
  }

  render() {
    return (
      <section className="ThemesHero Section u-sm-pv++">
        <div className="InnerWrapper Grid Grid--alignCenter u-md-sectionPadding u-lg-sectionPadding u-md-sectionPadding--bottom u-lg-sectionPadding--bottom [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
          <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
            <h1 className="u-mega u-sm-mb0">Premium Themes</h1>
          </div>
          <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-mt">
            <h2 className="u-kilo">I create minimalistic, hand-crafted themes for the variety of platforms.</h2>
          </div>
          <article className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before3of12 u-lg-before3of12 u-md-after1of12 u-lg-after1of12 u-mt">
            <p className="u-delta">
            Welcome to my theme directory.<br className="u-textBreakeOnMdLg"></br>
            If you want to boost your website looks,<br className="u-textBreakeOnMdLg"></br>
            with a carefully designed & coded theme,<br className="u-textBreakeOnMdLg"></br>
            this is the right place for you to be!</p>
            <p className="u-delta">
            Go check out my current releases,<br className="u-textBreakeOnMdLg"></br>
            or contact me directly for your custom<br className="u-textBreakeOnMdLg"></br>
            design and implementation.</p>
            <a className="ThemesBtn ThemesBtn-icon ThemesBtn-icon--browse" onClick={this.scrollToBrowse}><span>Browse Themes</span></a>
          </article>
        </div>
      </section>
    );
  }
};

export default ThemesHero;
