import React from 'react';
import $ from 'jquery';

class Themes extends React.Component {
  render() {
    return (
      <div>
        <section className="ThemesList InnerWrapper js-browseHook">
          <div className="Section Grid Grid--alignCenter u-sm-mt++ u-md-sectionMarginHalf u-lg-sectionMarginHalf [ u-md-pv+ u-lg-pv+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
            <div>
              <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
                <h1 className="u-sm-mb0">My Themes</h1>
              </div>
            </div>
          </div>
          <div className="Section Grid Grid--alignCenter u-sm-mt++ [ u-md-pv+ u-lg-pv+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
            <div>
              <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12 u-md-after1of12 u-lg-after1of12 u-mt">
                <h2>Square</h2>
                <div className="ThemesItem">
                  <header className="ThemesItem-header">
                    <span className="ThemesItem-dot ThemesItem-dot--red"></span>
                    <span className="ThemesItem-dot ThemesItem-dot--yellow"></span>
                    <span className="ThemesItem-dot ThemesItem-dot--green"></span>
                  </header>
                  <div className="ThemesItem-content">
                    <img src="src/images/themes-square-theme.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-mt++">
                <div className="ThemeCard">
                  <article>
                    <p>Square is a clean & simple theme for tumblr. Focused on presenting your photos in a square form, could be a great start for you portfolio.</p>
                    <ul className="ThemesList-features">
                      <li>mobile first / fully responsive</li>
                      <li>support for all post types</li>
                      <li>fully customizable</li>
                      <li>with infinite scrolling</li>
                    </ul>
                    <a className="ThemesBtn ThemesBtn--full ThemesBtn-icon ThemesBtn-icon--preview" href="http://get-square.tumblr.com"><span>Preview Demo</span></a>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Themes;
