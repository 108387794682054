import React from 'react';
import { Link } from 'react-router';
import LazyLoad from 'react-lazy-load';

class Hautec extends React.Component {
  render() {
    return (
      <main className="Projects">

        <header className="ProjectsHeader ProjectsHeader--hautec">
          <Link to="/" className="ProjectsExit"><span></span></Link>
          <div className="ProjectsHeader-content InnerWrapper u-md-pb+ u-lg-pb+ u-sm-ph-">
            <h1>Hautec</h1>
          </div>
        </header>

        <section className="ProjectsContent InnerWrapper">

          <header className="ProjectsMeta ProjectsContent-separator Grid Grid--alignCenter u-pv+ u-md-pv++ u-lg-pv++">
            <div className="Grid-cell u-size11of12 u-md-size2of12 u-lg-size2of12">
              <h4 className="ProjectsMeta-label u-mb0">Year</h4>
              <h3 className="u-mb0">2016</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-md-after2of12 u-lg-after2of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Scope:</h4>
              <h3 className="u-mb0">Website</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size5of12 u-lg-size5of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Tools:</h4>
              <h3 className="u-mb0">Sketch, HTML, CSS, JavaScript</h3>
            </div>
          </header>

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-md-after4of12 u-lg-after4of12">
                <img src="src/images/hautec-logo.png" />
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt+">
                <h2>About</h2>
                <p>Hautec Polska from its early years has been a leading manufacturer of flush frames. Since 2001, thanks to constant development and modernization, their frames became recognized not only on a domestic market, but also abroad. Thanks to systematic developments they also offer following services:</p>
                <ul>
                  <li>- Flush frames production</li>
                  <li>- Metal profiles and sheet metal laser cutting</li>
                  <li>- Manual and automatic welding</li>
                  <li>- Sheet metal and frames tooling</li>
                  <li>- Powder coating</li>
                </ul>
                <span>(source: hautec.com.pl)</span>
              </div>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pv++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Problem</h2>
              <p>Hautec is a heavy-duty company located in Skawina, neighbourhood of Kraków, Poland.</p>
              <p>The biggest obstacle was that the vast majority of users are the people who know what they're looking for. They either know the company or are in the industry and are looking for some alternatives.</p>
              <p>I also wanted to focus on accessibility. What do we do? What differentiates of from competition? Where to find us? How to contact us?</p>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt+">
              <h2>To be done</h2>
              <p>They needed a new website, to show they are staying fresh and up-to-date. The old website used to be a relict of the late 90's / early 2000's.</p>
              <p>The approach was quite simple. As little text as possible, while focusing on the visual content - strong and clear photos.</p>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>General feeling</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>Metal all around. A lot of heavy duty machines working day and night. Scent of grease, oil and omnipresent metal fillings.</p>
              <p>How to present it to the user in a way that he can really feel the atmosphere?</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/hautec-feeling.jpg" />
              </LazyLoad>
            </div>
          </div>

        </section>

        <section className="ProjectsContent">

          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Website</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>We agreed that a vertical slider would fit the company's goal to showcase their work, equipment and level of complexity of what they do.</p>
              <p>The interaction pattern we used, was to keep everything on one page, without needing the user to leave it ever.</p>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-pv++">
            <LazyLoad>
              <img src="src/images/hautec-website.jpg" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
                <h2>Colors</h2>
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
                <p>The main color choice was obvious, as they already had a dark-blue logotype. The only thing I adjusted was its shade, so that it looks better on screen.</p>
                <p>Apart from that different sets of typography / background colors, all in shades of grey</p>
              </div>
            </div>
            <div className="ProjectImage ProjectImage--center u-pt++ u-ph-">
              <LazyLoad>
                <img src="src/images/hautec-colors.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
                <h2>Typography</h2>
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
                <p>I decided to use a combination of two sans-serif fonts, as they are readable and enchance the tech climate of the company</p>
                <p>I went with Fira Sans for headings and Source Sans Pro for the body text and some minor subheadings.</p>
              </div>
            </div>

            <div className="Grid Grid--alignCenter u-pv++">
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
                <LazyLoad>
                  <img src="src/images/hautec-typography-1.png" />
                </LazyLoad>
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt++">
                <LazyLoad>
                  <img src="src/images/hautec-typography-2.png" />
                </LazyLoad>
              </div>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Icons</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>This wasn't a typical icon work, as it usually looks like. There wasn't another settings or menu glyph to create.</p>
              <p>Five symbols representing five major areas of the company's work were quite a challenge. How to make them unique but also clear to understand what they mean?</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/hautec-icons.png" />
              </LazyLoad>
            </div>
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">

          <div className="Grid Grid--alignCenter u-pt++ u-pb+ u-md-pb++ u-lg-pb++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Credits</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>This project wouldn't come to life if not for the help from many great people. I'd like to mention and really thank them for all the cooperation and help :)</p>
              <ul className="ProjectsCredits ProjectsCredits--hautec">
                <li>- <a href="https://www.facebook.com/sewimedia">Sewi</a>, for the great video and photography, which are a foundation of the entire website. I coudn't have achieved the effect I wanted without them!</li>
              </ul>
            </div>
          </div>

        </section>

      </main>
    )
  }
}

export default Hautec;
