import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router';

import Header from './components/Header';
import About from './homepage/About';
import Work from './homepage/Work';
import SliderWrapper from './homepage/SliderWrapper';
import Dribbble from './homepage/Dribbble';
import Shots from './homepage/Shots';
import Contact from './homepage/Contact';
import Footer from './components/Footer';

class Home extends React.Component {
  render() {

    return (
      <main>
        <Header />

        <About />

        <Work />

        <SliderWrapper />

        <Dribbble />

        <Shots source="https://api.dribbble.com/v1/users/92629/shots?access_token=" />

        <Contact />

        <Footer />
      </main>
    )
  }
}

export default Home;
