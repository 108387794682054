import React from 'react';

import ShopHeader from './shop/ShopHeader';
import ThemesHero from './shop/ThemesHero';
import ThemesFeatures from './shop/ThemesFeatures';
import ThemesContact from './shop/ThemesContact';
import ThemesFaq from './shop/ThemesFaq';
import Footer from './components/Footer';
import Themes from './shop/Themes';

class Shop extends React.Component {
  render() {
    return (
      <main className="Shop">
        <ShopHeader />
        <ThemesHero />
        {this.props.children}
        <ThemesFeatures />
        <ThemesFaq />
        <ThemesContact />
        <Footer />
      </main>
    )
  }
}

export default Shop;
