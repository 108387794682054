import React from 'react';
import SlideEven from './SlideEven';
import SlideOdd from './SlideOdd';

var slides = [
  {
    'id': '0',
    'color': '#63004A',
    'title': 'Estimote',
    'text_description': 'Documentation of my 3-year journey at a beacon startup, creating an OS for real life.',
    'text_note': 'A general overview of my part in this story.',
    'html_url': '/estimote',
    'img': 'src/images/img-estimote.png'
  },
  {
    'id': '1',
    'color': '#6BBC6C',
    'title': 'Hacktory',
    'text_description': 'Hacktory is a community of hackers, getting together during short hackathons spread across Europe.',
    'text_note': 'I tried to solve their problem with the Android and iOS apps.',
    'html_url': '/hacktory',
    'img': 'src/images/img-hacktory.png'
  },
  {
    'id': '2',
    'color': '#FF7200',
    'title': 'Clockwork (Pirx3d)',
    'text_description': 'Pirx3d is a hardware oriented company who have designed and built Pirx - the most affordable 3D printer on the market.',
    'text_note': 'I was responsible for creating their visual identity.',
    'html_url': '/clockwork',
    'img': 'src/images/img-pirx.png'
  },
  {
    'id': '3',
    'color': '#22387D',
    'title': 'Hautec',
    'text_description': 'A new website for the local heavy-duty solutions manufacturer, that showcases their work and products.',
    'text_note': 'How to achive a strong, visual effect, while keeping it fresh.',
    'html_url': '/hautec',
    'img': 'src/images/img-hautec.png'
  }
];

class SliderWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      slides: slides
    };
  }

  render() {
    function isEven(n) {
      return n % 2 == 0;
    }

    var sliderIndex = this.state.slides.map((slide) => {

      if (isEven(slide.id)) {
        return (
          <div key={slide.id}>
            <SlideEven title={slide.title} description={slide.text_description} note={slide.text_note} url={slide.html_url} img={slide.img} color={slide.color} />
          </div>
        );
      } else {
        return (
          <div key={slide.id}>
            <SlideOdd title={slide.title} description={slide.text_description} note={slide.text_note} url={slide.html_url} img={slide.img} color={slide.color} />
          </div>
        );
      }
    });

    return (
        <div className="Showcase u-mt u-md-mt++ u-lg-mt++">
          {sliderIndex}
        </div>
    );
  }
};

export default SliderWrapper;
