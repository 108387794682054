import React from 'react';
import { Link } from 'react-router';

import Header from './components/Header';
import Footer from './components/Footer';


class Projects extends React.Component {
  render() {

    return (
      <main>
        {this.props.children}
      </main>
    )
  }
}

export default Projects;
