import React from 'react';
import { Link } from 'react-router';
import LazyLoad from 'react-lazy-load';

class Hacktory extends React.Component {
  render() {
    return (
      <main className="Projects">

        <header className="ProjectsHeader ProjectsHeader--hacktory">
          <Link to="/" className="ProjectsExit"><span></span></Link>
          <div className="ProjectsHeader-content InnerWrapper u-md-pb+ u-lg-pb+ u-sm-ph-">
            <h1>Hacktory for iOS & Android</h1>
          </div>
        </header>

        <section className="ProjectsContent InnerWrapper">

          <header className="ProjectsMeta ProjectsContent-separator Grid Grid--alignCenter u-pv+ u-md-pv++ u-lg-pv++">
            <div className="Grid-cell u-size11of12 u-md-size2of12 u-lg-size2of12">
              <h4 className="ProjectsMeta-label u-mb0">Year</h4>
              <h3 className="u-mb0">2015</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size3of12 u-lg-size3of12 u-md-after2of12 u-lg-after2of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Scope:</h4>
              <h3 className="u-mb0">iOS / Android App Design, Prototyping</h3>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size5of12 u-lg-size5of12 u-sm-mt">
              <h4 className="ProjectsMeta-label u-mb0">Tools:</h4>
              <h3 className="u-mb0">Sketch, Origami, Swift</h3>
            </div>
          </header>

          <div className="ProjectsContent-separator u-pv++">
            <div className="Grid Grid--alignCenter">
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
                <img src="src/images/hacktory-logo.png" />
              </div>
              <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt+">
                <h2>About</h2>
                <p>Hacktory is a community of hackers, getting together during short hackathons spread across Europe. They merge two worlds – of physical and digital – to dive deep into the new connectivity era, called the Internet of Things. They play with robots, quadcopters, Oculus, beacons, Glass, Tango - you name it, they bring it.</p>
                <span>(source: hacktorykrakow.com)</span>
              </div>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pv++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Problem</h2>
              <p>After about two years of organizing IoT hackathons we've observed that the conversion rate of people registering to the event and then using page like eventbrite to get tickets and then coming to the event in person is still not satysfying.</p>
              <p>The whole process was a real pain in the ass for the end user. Using different pages to register, then checking your email to confirm your participation with the main part of the discussion taking place on facebook != mess.</p>
              <p>An app that would allow you to easily register (one time and forget it), that would be your companion during each hackathon, with live updates and announcements seemed like a good idea to get people more involved.</p>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12 u-sm-mt+">
              <h2>To be done</h2>
              <p>The most important requirement was that the app should be available on both iOS and Android.</p>
              <p>Then we've agreed on the MVP + thought what would be nice to have, if there is enough time left (of course there wasn't...)</p>
              <ul>
                <li>1) configuration / settings panel, integrated with Estimote Cloud</li>
                <li>2) news feed that would be temporarily used as the announcement feed during the event (until we have time to separate both)</li>
                <li>3) list of projects available during the current event, with description, ideas, tools needed, etc.</li>
              </ul>
            </div>
          </div>

        </section>

        <section className="ProjectsContent">

          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>The App</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>The design of the app reflects the branding of Hacktory. Characteristic green, combined with dark grey and shades of white.</p>
              <p>As for the typography, Dosis as a strong typeface for headings (is also used in logotype), in combination with Lato for the paragraph text.</p>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-pv++">
            <LazyLoad>
            <img src="src/images/hacktory-overview.png" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>App Icon</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>Three color variations of the icons, with the use of three brand colors mentioned earlier.</p>
              <p>The symbol inside is the part of the logotype, symbolizing the industrial/tech character of the organization (hack/fac)-tory.</p>
            </div>

            <div className="ProjectImage ProjectImage--center u-sm-ph- u-pv++">
              <LazyLoad>
                <img src="src/images/hacktory-appicon.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Pattern / Illustration</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>I wanted to create a background illustration that could be used across the app, but also become widely recognizable when it comes to general Hacktory-brand. Something fresh but keeping up with the existing branding.</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/hacktory-pattern.png" />
              </LazyLoad>
            </div>
          </div>

          <div className="ProjectsContent-separator Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Icons</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>The icons should have had a clear message behind them, but also they needed to have a strong, almost industrial feeling.</p>
              <p>That's why I decided to have a pretty bold stroke around them, and create an alternative version for some of the icons (to differentiate them on :active state).</p>
            </div>

            <div className="ProjectImage u-pv++">
              <LazyLoad>
                <img src="src/images/hacktory-icons.png" />
              </LazyLoad>
            </div>
          </div>

        </section>

        <section className="ProjectsContent">
          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Android Overview</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>Android was our primary platform, and it was very important for us, that the interaction would be seamless and easy.</p>
              <p>Some typical android patterns were used, like the tab bar menu, along with the solution borrowed from the Facebook Paper app - the projects carousel.</p>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-sm-ph- u-pv++">
            <LazyLoad>
              <img src="src/images/hacktory-android.png" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

          <div className="InnerWrapper Grid Grid--alignCenter u-pt++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>iOS Overview</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>The difference with the Android version was purely within the platform adaptation. Tab bar is in the bottom, and the top section of the app behaves differently in various situations.</p>
              <p>The carousel pattern and modals for individual item (news/projects) were also implemented.</p>
            </div>
          </div>

          <div className="ProjectImage ProjectImage--center u-sm-ph- u-pv++">
            <LazyLoad>
              <img src="src/images/hacktory-ios.png" />
            </LazyLoad>
          </div>

          <div className="ProjectsContent-separator InnerWrapper">
          </div>

        </section>

        <section className="ProjectsContent InnerWrapper">

          <div className="Grid Grid--alignCenter u-pt++ u-pb+ u-md-pb++ u-lg-pb++">
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after3of12 u-lg-after3of12">
              <h2>Credits</h2>
            </div>
            <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-after1of12 u-lg-after1of12">
              <p>This project wouldn't come to life if not for the help from many great people. I'd like to mention and really thank them for all the cooperation and help :)</p>
              <ul className="ProjectsCredits ProjectsCredits--hacktory">
                <li>- <a href="https://github.com/chwastek">Filip</a>, <a href="https://github.com/pikor">Łukasz</a>, <a href="https://github.com/airalex">Alex</a> for the extra time they've spent on developing the app. It was a real pleasure working with them on this project.</li>
              </ul>
            </div>
          </div>

        </section>

      </main>
    )
  }
}

export default Hacktory;
