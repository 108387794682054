import React from 'react';
import { Router, Route, IndexRoute, Link, browserHistory, hashHistory } from 'react-router';

import Home from '../Home';
import Cv from '../Cv';
import Projects from '../Projects';

import Estimote from '../projects/Estimote';
import Hacktory from '../projects/Hacktory';
import Temperature from '../projects/Temperature';
import Clockwork from '../projects/Clockwork';
import Hautec from '../projects/Hautec';

import Shop from '../Shop';
import Themes from '../shop/Themes';
import Bookworm from '../shop/Bookworm';


var routes = (
  <Router history={browserHistory} onUpdate={() => window.scrollTo(0,0)}>
    <Route path="/" component={Home}></Route>
    <Route path="cv" component={Cv}></Route>
    <Route component={Projects}>
      <Route path="estimote" component={Estimote} />
      <Route path="hacktory" component={Hacktory} />
      <Route path="clockwork" component={Clockwork} />
      <Route path="hautec" component={Hautec} />
    </Route>
    <Route component={Shop}>
      <Route path="themes" component={Themes} />
    </Route>
    <Route path="bookworm" component={Bookworm} />
  </Router>
);

export default routes;
