import React from 'react';

class About extends React.Component {
  render() {
    return (
      <section className="Section InnerWrapper Grid Grid--alignCenter u-md-sectionMargin u-lg-sectionMargin [ u-sm-mt++ ] [ u-md-pb+ u-lg-pb+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
        <div className="Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">
          <h1 className="u-mega u-sm-mb0">Hello there!</h1>
        </div>
        <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-mt">
          <h2 className="u-kilo">My name is Maciej and I’m a <span className="u-underline">Product Designer</span> & <span className="u-underline">Front-End Developer</span></h2>
        </div>
        <article className="Grid-cell u-size11of12 u-size4of12 u-md-size4of12 u-lg-size4of12 u-md-before3of12 u-lg-before3of12 u-md-after1of12 u-lg-after1of12 u-mt">
          <p className="u-delta">
          Welcome to my world. <br className="u-textBreakeOnMdLg"></br>
          I'm passionate about creating products, <br className="u-textBreakeOnMdLg"></br>
          overcoming obstacles that occur along <br className="u-textBreakeOnMdLg"></br>
          the journey and make users happier <br className="u-textBreakeOnMdLg"></br>
          and more productive with their work. </p>
          <p className="u-delta">I'm currently available for hire, <br className="u-textBreakeOnMdLg"></br>
          full time or for individual projects. <br className="u-textBreakeOnMdLg"></br>
          That's why I encourage you to drop <br className="u-textBreakeOnMdLg"></br>
          me an email. Let's chat.</p>
        </article>
      </section>
    );
  }
};

export default About;
