import React from 'react';
import { Link } from 'react-router';
import LazyLoad from 'react-lazy-load';

class SlideOdd extends React.Component {
  render() {
    return (
      <Link to={this.props.url} className="Showcase-inner [ u-md-ph u-lg-ph- u-xlg-ph0 ]" style={{backgroundColor: this.props.color}}>
          <div className="ShowcaseRow InnerWrapper Grid Grid--alignCenter">

            <div className="ShowcaseColumn Grid-cell u-size11of12 [ u-md-size6of12 u-lg-size6of12 ] [ u-sm-pv+ ] u-lg-pr+">
              <div className="ShowcaseColumn-item">
                <div className="ShowcaseDetails">
                  <h1>{this.props.title}</h1>
                  <p>{this.props.description}</p>
                  <p className="u-mb0">{this.props.note}</p>
                </div>
                <div className="ShowcaseLink">
                  <span>Read more about this project &#8640;</span>
                </div>
              </div>
            </div>

            <div className="ShowcaseColumn u-size11of12 [ u-md-size6of12 u-lg-size6of12 ]">
              <div className="ShowcaseColumn-item ShowcaseColumn-offsetRight">
                <LazyLoad>
                  <img className="ShowcaseImage u-sm--mb--" src={this.props.img} />
                </LazyLoad>
              </div>
            </div>

          </div>

      </Link>
    );
  }
};

export default SlideOdd;
