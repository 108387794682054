import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="Section InnerWrapper Grid Grid--alignCenter u-md-sectionMargin u-lg-sectionMargin u-sm-mt++ u-pb [ u-md-pb+ u-lg-pb+ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
        <copyright className="Footer-copyright Grid-cell u-size11of12 u-md-size12of12 u-lg-size12of12">Pixels & Code &copy; 2016-2018. Maciej Jasiński.</copyright>
      </footer>
    );
  }
};

export default Footer;
