import $ from 'jquery';
import React from 'react';

class ThemesContact extends React.Component {
  render() {
    return (
      <section className="ThemesContact Section u-sm-mt++ u-sm-pv++">
        <div className="FAQ-contact InnerWrapper Grid Grid--alignCenter u-md-sectionMargin u-lg-sectionMargin [ u-md-pv++ u-lg-pv++ ] [ u-md-ph u-lg-ph- u-xlg-ph0 ]">
          <div className="Grid-cell u-size11of12 u-md-size6of12 u-lg-size6of12">
            <h2 className="ThemesContact-heading u-mb0">Haven't found the answer?<br className="u-textBreakeOnMdLg"></br>Just drop me a message.</h2>
          </div>
          <div className="Grid-cell u-size11of12 u-md-size4of12 u-lg-size4of12 u-md-before1of12 u-lg-before1of12 u-md-after1of12 u-lg-after1of12 u-mt">
            <div className="ThemeContact-btnWrapper">
              <a className="ThemesBtn ThemesBtn-icon ThemesBtn-icon--contact" href="mailto:contact@maciejjasinski.com"><span>Contact me</span></a>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default ThemesContact;
